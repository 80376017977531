<template>
  <div>
    <section-banner v-for="(section, index) in sections" :key="index" :model="section"></section-banner>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: 'WhatWeDo',
  components: {
    SectionBanner
  },
  data() {
    return {
      sections: [
        {
          WhatWeDo: true,
          Name: "What We Do",
          What: [
          {
            img: require("@/assets/img/Natalie.jpg"),
            title: "Building Your Business",
            subtitle: "Put the right blocks in place",
            link: "/building-your-business"
          },
          {
            img: require("@/assets/img/Vuyani4.jpg"),
            title: "Litigation",
            subtitle: "Lawyer Up",
            link: "/litigation"
          },
          {
            img: require("@/assets/img/Helanie.jpg"),
            title: "Family Law",
            subtitle: "Untangling the ties that bind",
            link: "/family-law"
          },
          {
            img: require("@/assets/img/Josh.jpg"),
            title: "Property Law",
            subtitle: "Buying, selling, or developing your property",
            link: "/property-law"
          },
          {
            img: require("@/assets/img/Natalie2.jpg"),
            title: "Labour Law",
            subtitle: "Finding agreeable working relationships",
            link: "/labour-law"
          },
          {
            img: require("@/assets/img/Josh2.jpg"),
            title: "Notarial Services",
            subtitle: "Vetting your documents for use worldwide",
            link: "/notarial-services"
          },
          {
            img: require("@/assets/img/Dan.jpg"),
            title: "Trusts",
            subtitle: "Structuring and managing your assets",
            link: "/trusts"
          },
          {
            img: require("@/assets/img/Helanie2.jpg"),
            title: "Estate Planning",
            subtitle: "Getting your affairs in order",
            link: "/estate-planning"
          },
          {
            img: require("@/assets/img/Natalie.jpg"),
            title: "Compliance",
            subtitle: "Abiding by the rules",
            link: "/compliance"
          }
        ]
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>

</style>